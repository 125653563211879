import React from 'react'
import PropTypes from 'prop-types'
import Img from "gatsby-image"
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import ThemeContext from "../../context/ThemeContext"
import Content from '../Content'

export default class Story extends React.Component {
  componentDidMount() {
    this.context.showNav()
  }
  render() {
    const { content, contentComponent, title, description, featuredimage, color, overlay, locationId } = this.props
    const PostContent = contentComponent || Content
    const blockquoteColor = (color && color !== '#ffffff' && color.toLowerCase() !== 'white') ? color : '#000000';
    const currentPage = ((typeof window !== 'undefined' && window.location && window.location.href) || '/').split('?').shift().split('#').shift()
    const mailTo = `mailto:?subject=${encodeURIComponent('A Message from The Book Lover Club!')}&body=${encodeURIComponent(`Check out this great article on The Book Lover Club: ${currentPage}`)}`
    return (
      <ThemeContext.Consumer>
        {theme => (
          <div className="cStory cSplitScreen">
            <div className={`cStory-cover cSplitScreen-cover ${overlay}`}>
            {featuredimage && featuredimage.childImageSharp && featuredimage.childImageSharp.fluid ? 
              <Img
                  fluid={featuredimage.childImageSharp.fluid}
                  alt={title}
                  className="cStory-image"
                  />
              : (
                <div className="cStory-image-wrapper">
                  <img className="cStory-image" src={featuredimage} alt={title} />
                </div>
              )}
              <div className="cStory-container">
                <h1 className="cStory-title" style={{ color: color ? color : null }}>
                  {title}
                </h1>
              </div>
            </div>
            <div className="cStory-content cSplitScreen-content">
              <AniLink fade to={theme.prevPath ? theme.prevPath : '/'} duration={0.25}  className="close-icon" aria-label="Close" state={{ locationId: locationId }}></AniLink>
              <div className="cStory-inner">
                <div className="cStory-header">
                  <p className="cStory-description">{description}</p>
                </div>
                <div className="cStory-body" style={{ '--blockquote-color': blockquoteColor }}>
                  <PostContent content={content} className="cStory-text" />
                  <AniLink fade to={theme.prevPath ? theme.prevPath : '/'} duration={0.25}  className="close-btn" aria-label="Close" state={{ locationId: locationId }}>Close</AniLink>
                </div>
                <div className="cStoryLinks-wrapper">
                  <p className="cStoryLinks-title">Share:</p>
                  <div className="cStoryLinks">
                    <div className="cStoryLink facebook" data-href={currentPage}>
                      <a target="_blank" rel="noopener noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentPage)}`}>
                        <img src="/img/facebook.png" alt="facebook" />
                      </a>
                    </div>
                    <div className="cStoryLink twitter">
                      <a rel="noopener noreferrer" href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(currentPage)}`}>
                        <img src="/img/twitter.png" alt="twitter" />
                      </a>
                    </div>
                    <div className="cStoryLink pinterest">
                      <a data-pin-do="buttonBookmark" rel="noopener noreferrer" href={`http://pinterest.com/pin/create/button/?url=${encodeURIComponent(currentPage)}`}>
                        <img src="/img/pinterest.png" alt="pinterest" />
                      </a>
                    </div>
                    <div className="cStoryLink email">
                      <a href={mailTo}>
                        <img src="/img/email.png" alt="email" />
                      </a>
                      <script async defer src="//assets.pinterest.com/js/pinit.js" data-pin-sticky="false"></script>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </ThemeContext.Consumer>
    )
  }
}

Story.contextType = ThemeContext

Story.propTypes = {
  content: PropTypes.node,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  featuredimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
}
