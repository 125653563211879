import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Story from '../components/Story'
import { HTMLContent } from '../components/Content'

export const StoryTemplate = ({
  content,
  contentComponent,
  title,
  description,
  featuredimage,
  color,
  overlay,
  locationId,
  helmet
 }) => {
  return (
    <section className="section">
      {helmet || ''}
      <Story
        content={content}
        contentComponent={contentComponent}
        title={title}
        description={description}
        featuredimage={featuredimage}
        color={color}
        overlay={overlay}
        locationId={locationId}
      />
    </section>
  )
}

StoryTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  featuredimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  color: PropTypes.string,
  overlay: PropTypes.string,
  locationId: PropTypes.string,
  helmet: PropTypes.object
}

const StoryTab = ({ data, location }) => {
  const { markdownRemark: post } = data
  const textColor = location && location.state && location.state.color ? location.state.color : '#ffffff';
  const imageOverlay = location && location.state && location.state.overlay ? location.state.overlay : 'dark';
  const locationId = location && location.state && location.state.locationId ? location.state.locationId : '';
  return (
    <Layout>
      <StoryTemplate
        content={post.html}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        featuredimage={post.frontmatter.featuredimage}
        description={post.frontmatter.description}
        color={post.frontmatter.color ? post.frontmatter.color : textColor}
        overlay={post.frontmatter.overlay ? post.frontmatter.overlayn : imageOverlay}
        locationId={locationId}
        helmet={
          <Helmet titleTemplate="%s">
            <title>{`${post.frontmatter.title}`}</title>
            <meta property="og:title" content={post && post.frontmatter && post.frontmatter.title} />
            <meta property="og:url" content={location && location.pathname} />
            <meta property="og:description" content={post && post.frontmatter && post.frontmatter.description} />
            <meta name="description" content={post && post.frontmatter && post.frontmatter.description} />
            <meta property="og:image" content={(post.frontmatter.featuredimage && post.frontmatter.featuredimage.childImageSharp && post.frontmatter.featuredimage.childImageSharp.fluid && post.frontmatter.featuredimage.childImageSharp.fluid.src) ||  "/img/book-logo.png"} />
          </Helmet>
        }
      />
    </Layout>
  )
}

StoryTab.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default StoryTab

export const pageQuery = graphql`
  query StoryTabByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        color
        description
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 526, quality: 92) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        overlay
      }
    }
  }
`
